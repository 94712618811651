.record-modal{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.record-modal-content{
    position: relative;
    background-color: white;
    padding: 20px 30px;
    border: 1px solid black;
    border-radius: 10px;
    min-width: 400px;
    display: flex;
    justify-content: center;
}
.record-modal-content .fa-xmark{
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 30px;
    cursor: pointer;
}
.record-add{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.record-add input{
    width: 300px;
    text-align: center;
}
.record-update{
    margin-top:10px;
    padding:8px 100px;
    background-color: #4952b8;
    color: white;
    cursor: pointer;
}
.record-btn-group{
    display: flex;
    gap:5px;
    margin-top: 15px;
}
.record-add-update{
    background-color: #414bb3;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 5px;
    border-radius: 5px;
}
.record-add-new{
    background-color: red;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
}
.record-cancel{
    background-color: rgb(136, 129, 129);
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 10px;
}
.record-title{
    font-size: 18px;
}


@media (max-width: 600px) {
    .record-add input{
        width: 280px;
        text-align: center;
    }
    .record-update{
        margin-top:10px;
        padding:8px 50px;
        background-color: #4952b8;
        color: white;
        cursor: pointer;
    } 
}

