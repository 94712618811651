.dialog_navigator_button {
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  box-shadow: 0 0 3px gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  color: black;
}

.zoom_button {
  cursor: pointer;
}

.tab-0 {
  min-width: 1200px;
  height: 100%;
}

.tab-0 .icon_filter_default {
  fill: black;
}

.page_active {
  background-color: #fcff05;
}


.button_dropzone {
  width: 130px;
}

.document-dialog .MuiCheckbox-root svg {
  fill: #000;
}

.document-dialog .MuiCheckbox-root.Mui-checked svg {
  fill: #fff;
}

/* .modal-confirm-list .button-close {
    display: inline-block;
    padding: 4px 15px;
    border: 1px solid black;
    box-shadow: 0 0 3px #0004;
    cursor: pointer;
} */

.button-confirm {
  display: inline-block;
  padding: 4px 30px;
  border: 1px solid black;
  box-shadow: 0 0 3px #0004;
  cursor: pointer;
  margin-top: 25px;
}

.dropzone-absolute {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #80a0bac1;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.dropzone-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60%;
}

.dropzone-item {
  color: white;
  font-weight: 600;
}

.icon-dropzone {
  font-size: 60px !important;
}

.show-scroll-bar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.show-scroll-bar::-webkit-scrollbar:vertical {
  width: 14px;
}

.show-scroll-bar::-webkit-scrollbar:horizontal {
  height: 14px;
}

.show-scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 8px;
  border: 2px solid #ffffff;
}

.show-scroll-bar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #ffffff;
}

.stack-confirm {
  position: absolute;
  top: 25%;
  right: 4%;
  transform: rotate(10deg);
  
}

.type-confirm {
  color: red;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  padding: 0.2rem 0.5rem;
  border: 2px solid red;
  opacity: 1;
}

.skew-icon {
  transform: rotate(-45deg);
}

.stack-process-cancel {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}