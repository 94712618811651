.close-sidebar-arrow-right {
    position: fixed;
    right: 350px;
    top: 60vh;
    background-color: white;
    z-index: 99;
    padding: 30px 2px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    cursor: pointer;
    font-size: 12px;
    box-shadow: -1px 0px 3px 0px rgb(126 126 126 / 27%);
    .icon {
        padding-left: 2px;
    }
}


@media (max-width: 500px){
    .close-sidebar-arrow-right {
        display: none;
    }
}
