.frame-wp{
    display: flex;
}

.frame-content{
    width: 100vw;
}

@media (max-width: 500px){
    .frame-wp{
        flex-direction: column;
    }
    .frame-content{
        height: calc(100vh - 47px);
    }
}