.cg-domain-link{
    color:#414bb2;
    margin-top: 15px;
    display: flex;
    border-bottom: 1px solid #414bb2;
    width: fit-content;
    line-height: 15px;
    cursor: pointer;
    font-size: 15px;
}

.cg-domain-wp{ 
    position: absolute;
    background-color: #f1f1f1;
    top: -50%;
    left: -8%;
    padding: 20px 50px 40px 50px;
    border-radius: 5px;
    border: 0.5px solid rgb(146, 146, 146);
}
.cg-domain-content{
    min-width: 500px;
}
.cg-domain-content > p{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.cg-domain-close{
    position: absolute;
    right: 2px;
    top:0;
    cursor: pointer;
}

.cg-domain-list-item{
    margin-top: 15px;
}

.cg-domain-list-item > p{
    margin-bottom: 5px !important;
}

.cg-url-close{
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 2px;
}
.cg-url-confirm{
    background:#414bb2;
    color: white;
    padding: 3px 8px 0px 8px;
    border-radius: 3px;
    cursor: pointer;
}

.p-url{
    padding-top: 12px;
    padding-bottom: 10px !important;
    padding-right: 65px;
    font-size: 18px !important;
}


@media (max-width: 500px) {
    .cg-domain-content{
        min-width: 260px;
    }
    .cg-domain-wp{ 
        top: -30%;
        left: -5%;
    }
}