.f-navbar{
    position: absolute;
    left: 10px;
    top: 92px;
    max-width: 110px;
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 2px solid rgb(99, 95, 95);
    background-color: rgba(146, 151, 156, 0.723);
}

.f-list{
    list-style: none;
    padding: 8px 5px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.f-item{
    border: 1px solid rgb(14, 10, 248);
    cursor: pointer;
}
.f-active{
    border: 1px solid red;
}

.f-item img{
    max-width: 90px;
}


.f-navbar::-webkit-scrollbar {
    width: 0px;
    /* width of the entire scrollbar */
}
  
.f-navbar::-webkit-scrollbar-track {
    background: #878282;
    border-radius: 10px;
    /* color of the tracking area */
}
  
.f-navbar::-webkit-scrollbar-thumb {
    background-color: #0883e7;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
    /* border: 2px solid #da00db; */
    /* creates padding around scroll thumb */
}
.f-navbar::-webkit-scrollbar {
    width: 6px;
    display: block;
    /* width of the entire scrollbar */
}

.f-btn-navbar {
    position: absolute !important;
    top: 59px;
    left: 10px;
    font-size: 12px !important;
}

@media (max-width: 500px) {
    .f-navbar{
        top: 40px;
        max-width: 90px;
    }
    .f-item img{
        max-width: 70px;
    }
    .f-btn-navbar {
        top: 5px;
        transform: scale(0.8);
        left: 0px;
    }
    .show-scroll-bar{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}