
.cg-child-summary{
    min-height: 45px !important;
}
.cg-child-summary>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 0px !important;
}
.cg-child-title{
    display: flex;
    gap:4px;
}