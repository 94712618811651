p {
    margin: unset !important;
}
.schedule-container {
    margin: 15px;
}

.btn-schedule {
    border: 1px solid #333 !important;
    color: #333 !important;
}

.schedule-item {
    border: 1px solid #828282 !important;
    background-color: #dff2ff;
    padding: 5px;
    border-radius: 4px;
}

.bg-schedule-me {
    background-color: #a4daff;
}

.modal-assign-task {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    background: white;
    color: #333;
    width: calc(300px + 10vw);
    padding: 20px;
    .react-datepicker-wrapper {
        width: unset;
    }
}

.checkbox-show-time {
    span {
        padding-top: 4px;
    }
}
.cwp {
    max-width: 90vw !important;
    max-height: 80vh !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-schedule {
    width: fit-content !important;
    max-width: 90vw !important;
    max-height: 80vh !important;
    overflow: scroll;
    position: relative;

    table {
        background-color: white;
        font-size: 12px;
        white-space: nowrap; 
        // overflow: hidden;
        text-overflow: ellipsis !important;
        .line {
            position: absolute;
        }
        .checkbox-show-finish {
            position: absolute;
            right: 0;
        }
        thead {
            th {
                border: 1px solid #00000070;
                border-bottom: 0;
                vertical-align: middle;
                text-align: center;
            }

            .div-line {
                position: relative;
                height: 100%;
                width: 100%;

                .month {
                    position: absolute;
                    width: 100%;
                    text-align: right;
                    padding: 12px;
                }

                .process {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    text-align: left;
                    padding: 12px;
                }
            }
        }

        tbody {
            td {
                border: 1px solid #00000070;
                vertical-align: middle;
                min-width: 42px;
                padding: 10px 4px;
                font-weight: 600;
            }

        }

        .cell-hover {
            cursor: pointer;
            background: #dff2ff !important;
        }
        .cell-finish {
            background: #e7e6e6 !important;
        }
    }
}

.show-error {
    color: red !important;
    font-weight: bold !important;
    margin-left: 20% !important;
    font-size: 12px !important;
}

.icon-crud {
    font-size: 20px !important;
    margin-right: 5px;
}

.btn-color-unconfirm {
    background-color: #a4a4a4 !important;
    color: white !important;
}

.bg-confirmed {
    background-color: #e6e6e6;
}

.list-task {
    overflow-y: auto;
}
.tasks-draw{
    max-height: calc(100vh - 440px) !important;
}

.status-confirmed {
    background-color: #0091ff !important;
    color: white !important;
    cursor: default !important;
    border: none !important;
    &:hover {
        opacity: 1 !important;
    }
}

.border-time {
    border: 1px solid #333;
    border-radius: 4px;
    padding: 0 8px;
}

// .mr-56px {
//     margin-right: 56px !important;
// }

.ca_active {
    color: red;
}

.cu_active {
    color: blue;
}

.ctable {

    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid black;
}

.ctable td,
.ctable th {
    border: 1px solid #8f8f8f;
    padding: 0.5rem 1rem;
}

.ctable thead {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 3;
    // width: 25vw;
    background: white;
    border-bottom: 1px solid black;
}

.ctable td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
}

.ctable tbody th {
    font-weight: 300;
    font-style: italic;
    text-align: left;
    position: relative;
}
.ctable thead .ct02 {
    position: sticky;
    left: 0;
    z-index: 4 !important;
    background-color: white;
}

.ctable thead .ct01 {
    position: sticky;
    left: 0;
    z-index: 4 !important;
    background-color: white;
}

.ctable tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

caption {
    text-align: left;
    padding: 0.25rem;
    position: sticky;
    left: 0;
}

[role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 98vh;
    overflow: auto;
}

[role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
}

.ctooltip {
    position: relative;
    display: flex;
    justify-content: center;
}

.ctext-tooltip {
    display: none;
    position: absolute;
    top: -130%;
    font-size: 14px;
}

.ctooltip:hover .ctext-tooltip {
    display: inline-block;
}


.c-close {
    position: absolute;
    margin-bottom: 15px;
    // font-weight: bold;
    font-size: 15px;
    top: -12px;
    right: -12px;
    border-radius: 100%;
    background-color: white;
    padding: 1px 7px;
    border: 1px solid black;
    cursor: pointer;
    z-index: 10;
}

.btn-print-schedule {
    position: absolute;
    font-size: 15px;
    top: 8px;
    right: 25px;
    z-index: 10;
}

.finish-schedule {
    border: 1px solid red;
}

.modal-assign-multi-task {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    background: white;
    color: #333;
    width: calc(300px + 10vw);

    .header {
        background-color: #0091ff;
        padding: 5px 10px;
    }
    .title {
        color: white;
        font-size: 18px;
    }
    .btn-create {
        color: white;
        font-size: 16px;
        background-color: #235e8c;
        border: 1px solid white;
        padding: 0;
    }
    .area {
        font-size: 16px;
    }
}



@supports (-moz-appearance:none) {
    .modal-schedule {
        .line {
            bottom: 107px;
        }
    }
}

#skew-line-schedule{
    display: none;
}
.schedule-download-btn{
    border: 1px solid rgb(123, 120, 120);
    border-radius: 2px;
}