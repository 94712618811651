
.cg-wrapper > div{
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* gap:0px !important; */
    /* display: grid;
  grid-template-areas:
    'cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-3d cg-bar'; */
}

.cg-3d{
    /* display: flex; */
    /* grid-area: cg-3d; */
    width: calc(100% - 350px);
    height: 100vh;
    overflow: hidden;
    /* position: relative; */
}
.cg-3d #document-content{
    width: calc(100% - 350px) !important;
    position: absolute;
    top: 0;
}
.cg-3d .document-dialog{
   background-color: white;
}
.cg-bar{
    /* grid-area: cg-bar; */
    display: flex;
    height: 100vh;
    /* overflow: scroll; */
    /* overflow-y: scroll; */
    overflow: hidden;
    width: 350px !important;
    margin-left: auto;
}
.cg-bar-list{
    width: 100%;
}

.cg-3d iframe{
    width: 100%;
    height: 100vh;
    border: none;
}

.cg-bar-item{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c4663;
    padding-bottom: 6px;
    padding-top: 8px;
    color: white;
    padding-inline: 20px;
}

.cg-bar-item .cg-info{
    flex-grow: 1;
    text-align: center;
}

.cg-copy{
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
}

.cg-item{
    max-height: 45px !important;
    min-height: 45px !important;
}
.cg-item div{
    display: flex;
    justify-content: center;
    color: white !important;
}

.cg-bar-disable{
    background-color: #A6A6A6 !important;
}
.cg-bar-ac{
    background-color: #4f4e4c !important;
    margin: 0px !important;
    border-radius: 0px !important;
}
.cg-bar-ac .ac-parent-title {
    font-weight: 600;
    font-size: 14px;
}
.cg-bar-ac.records{
    background-color: #414bb3 !important;
    
}

.cg-details{
    background-color: white !important;
}

.cg-child-item{
    padding-inline: 5px;
    border: none !important;
}
.cg-child-item img{
    width: 122px;
}

.cg-child-item > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    cursor: pointer;
}

.cg-child-item > div.active{
    border: 1px solid black !important;
}
.cg-child-item.active{
    border: 1px solid black !important;
}

.combos-item{
    margin-left: 30px !important;
    cursor: pointer;
    padding-bottom: 5px;
    /* border-bottom: 1px solid rgb(185, 180, 180); */
}
.cg-child-item:hover{
    background-color: whitesmoke;
}

.cg-distance-item{
    width: 40%;
    background-color: gray !important;
    color:white !important
}
.cg-distance-item.active{
    background-color: #414bb3 !important;
}

.cg-bar-back-draw{
    display: flex;
    background-color: #414bb3;
    max-width: 100%;
    margin-inline: auto;
    /* margin-top: 20px; */
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(183, 183, 233);
    color:white;
    cursor: pointer;
}

.record-item-list{
    display: flex;
    align-items: center;
    gap: 4px;
    max-height: 300px;
    overflow: scroll;
}
.record-item-list i,.record-item-list p{
    cursor: pointer;
}
.record-item-list tr.active{
    background-color: #cfd3d9;
    border: none !important;
}
.record-item-list a{
    color:black;
}
.accorion-draw #documents {
    margin-bottom: 0 !important;
}

.sidebar-left {
    position: relative;
    height: calc(100vh - 55px);
}

.bottom-accordion {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.bottom-accordion .btn {
    background-color: gray;
    color: white;
    width: 40%;
    cursor: no-drop;
}
.bottom-accordion .btn-active {
    background-color: #414bb3 !important;
    cursor: pointer;
}
.bottom-accordion .btn:hover {
    background-color: gray;
}

.cg-wrapper #documents .views.cg-view {
    /* max-height: 300px; */
    /* max-height: calc(100vh - 370px); */
    overflow-y: scroll;
}
.record-list-item-body{
    max-height: inherit;
}

@media (max-width: 600px) {
    .cg-wrapper{
        grid-template-areas:
    'cg-3d' 'cg-bar';
    }   
}

.screen{
    cursor: pointer;
    /* border:2px solid white; */
    /* width: 100px; */
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 0px;
    right: 480px;
    border-radius: 5px;
    padding-inline: 8px;
    background-color: #2c4663;
    font-size: 14px;
    padding-block: 2px;
}
.screen:hover{
    border:1px solid white;
}
.question-sidebar{
    filter: blur(1px);
    border:3px solid blue;
}

@media (max-width: 500px){
    .sidebar-left {
        position: relative;
        height: calc(100vh - 55px);
        width: 100% !important;
    }
    .cg-wrapper{
        flex-direction: column;
    }
    .cg-3d{
        width: 100%;
    }
    #sidebar{
        width: 100% !important;
    }
}