.headerMenu{
    padding-top: 0px;
    background-color: #2c4663;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
    // max-height: 60px;
    .drawIcon {
        color: white;
        font-size: 12px;
        margin-bottom: 7px;
    }
    .item {
        padding: 6px 13px;
        // width: 95px;
        &:hover {
            cursor: pointer;
        }
    }
    .items{
        // margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 2px;

        img{
            padding: 5px 10px 2px 10px;
            width: 53px;
            height: 38px;
        }
        span{
            font-size: 10px !important;
            line-height: 13px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .item-no-active {
        background-color: #9e9e9e;
    }
    .item-active {
        background-color: #414bb2;
    }
}

.headerMenuToggle{
    background-color: #142941;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        color: white;
    }
}