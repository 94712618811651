@import '../variables';

#shapespark {
  overflow: hidden;
  position: relative;
  // position: fixed;
  // top: 0;
  height: 100%;
  // width: 100%;
  background: white;
}

/*--------------Style header start--------------*/

.header {
  width: 100%;
  //height: 60px;
  display: flex;

  .tabs {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid $main-color;

    .border-right {
      border-right: 1px solid $main-color;
    }

    button {
      float: left;
      outline: none;
      cursor: pointer;
      transition: 0.3s;
      font-size: 1rem;
      height: 100%;
      width: 33.33%;
      border: 0;
    }
  }

  .tab-link:after {
    content: '▲';
    color: #444;
    font-weight: bold;
    margin-left: 5px;
  }

  .tab-link-active {
    background: $main-color;
    color: white;
  }

  .tab-link-active:after {
    color: white;
    content: '▼';
  }
}

/*--------------Style header end--------------*/

/*--------------Style main start--------------*/
.main {
  display: flex;
  height: 100vh;

  .render {
    display: flex;

    iframe {
      width: 100%;
      border: none;
    }
  }

  .show-sidebar {
    top: 50%;
    position: fixed;
    right: 1rem;
    width: 25px;
    height: 25px;
    background: white;
    cursor: pointer;

    svg {
      font-size: 32px;
      color: $main-color;
    }
  }

  .close-sidebar {
    width: 32px;
    height: 32px;
    top: 50%;
    position: fixed;
    background-color: white;
    cursor: pointer;
    right: 350px;
    border-radius: 5px 0 0 5px;

    svg {
      font-size: 32px;
      color: $main-color;
    }
  }

  .sidebar {
    min-width: 350px;
    max-width: 350px;
    width: 100%;
    // overflow-y: auto;
    height: 100vh;

    .list-choose-colors {
      width: 100%;
      max-width: 280px;

      //border-radius: 5px 0 0 5px;
      img {
        width: 100%;
        height: 80px;
      }

      .title {
        margin: 15px 0 6px 0;
      }
    }
    .property {
      .max-height-draw-schedule {
        max-height: calc(100vh - 190px);
      }
    }

    .choose-colors {
      display: inline-block;
      margin: 8px;
    }

    .choose-color {
      float: left;
      width: 50%;
      padding: 5px;
      border: solid 1px #ffffff;
      cursor: pointer;

      span {
        font-size: 13px;
        text-align: center;
        display: grid;
      }
    }

    .MuiAccordion-root {
      margin: 0;
      z-index: 1;

      .ac-parent {
        background-color: #4F4E4C ;
        // background-color: #666666 ;
        color: white;

        .MuiSvgIcon-root {
          color: white;
        }

        border-bottom: 1px solid white;

        .ac-parent-title {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .MuiAccordionSummary-root {
        padding: 0 8px;
        min-height: 45px;
        text-align: center;
        width: 100%;

        &.Mui-expanded {
          margin: 0;
          min-height: 45px;
        }

        .MuiAccordionSummary-content {
          margin: 10px 8px;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0;
        //min-height: 45px;
        width: 100%;
        text-align: center;
      }
    }

    .selected-material {
      font-size: 10px;
      position: absolute;
      bottom: 2px;
    }

    .ac-not-exist .ac-parent {
      background-color: $not-exist-color;
      color: #9e9e9e !important;

      .MuiSvgIcon-root {
        color: #9e9e9e !important;
      }
    }

    .document-sidebar-not-invited .MuiCollapse-entered {
      overflow-y: auto;
      max-height: calc(100vh - 135px);
    }

    .document-sidebar-invited .MuiCollapse-entered {
      overflow-y: auto;
      max-height: calc(100vh - 90px);
    }
  }

  .accordion-custom-root {
    .MuiAccordionDetails-root {
      padding: 10px !important;
    }
  }
}

/*--------------Style main end--------------*/

/*--------------Style extensions start--------------*/
.extensions {
  max-height: 340px;
  overflow-y: auto;
  width: 100%;

  p {
    max-height: 340px;
    color: black;

    span {
      display: flex;
      padding: 5px 0;
    }
  }

  button {
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;

    .active {
      border: solid 2px $main-color;
    }
  }
}

/*--------------Style extensions end--------------*/

.active {
  border: solid 1px $main-color;
  border-radius: 2px;
}

.btn-popup {
  width: 100%;
  background-color: white;
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 12px;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  height: 45px;
}

.btn-popup-active {
  //background: $main-color;
  //color: white;
  color: black;
}

.btn-action {
  width: 100%;
  height: 45px;
  display: inline-flex;

  .btn {
    background-color: $main-color;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    height: 100%;
  }

  .btn-save,
  .btn-export {
    width: 50%;
  }

  .btn-distance {
    width: 50%;
    margin: 0 auto;
  }

  .distance-active {
    margin: 0 20px;
    background-color: #2b7ac1 !important;
    //background-color: #5B9BD5 !important;
  }

  .distance-no-active {
    margin: 0 20px;
    background-color: #ff0000 !important;
  }
}

#documents {

  .area-drop-zone {
    background-color: #dff2ff;
  }

  .view.step1{
    max-height: 46vh;
  }

  .views {
    // margin: 0 0.8rem;
    font-size: 0.9rem;
    white-space: pre-line;
    // max-height: 46vh;

    .date {
      text-align: left;
      font-weight: 500;
      padding-top: 0.8rem;
      padding-bottom: 0.2rem;
    }

    .MuiImageList-root {
      margin: 0;
      gap: 0.5rem !important;

      .MuiImageListItem-root {
        padding: 2px;
        border: 2px solid transparent;

        .MuiImageListItem-img {
          height: 0px !important;
        }
      }

      .draw-active {
        border: 2px solid #0e3359 !important;
      }
    }

    .MuiImageListItemBar-root {
      margin: 0;
    }

    .view-list {
      .MuiImageListItemBar-root {
        position: absolute;
        left: auto;
        right: 0;
        width: 50%;
        background-color: white;

        .MuiImageListItemBar-titleWrap {
          color: black;
          padding: 0 0 0 6px;
          height: 100%;
          text-align: left;

          .MuiImageListItemBar-title {
            margin-top: -5px;
            font-size: 0.8rem;
            white-space: pre-line;
          }
        }
      }

      .MuiImageListItem-img {
        width: 50%;
      }
    }

    .view-grid {
      .MuiImageListItemBar-root {
        width: 70px;
        margin: 0.8rem auto;
        color: white;
        // background-color: red;
        font-weight: bold;

        .MuiImageListItemBar-titleWrap {
          padding: 0;
        }

        .MuiImageListItemBar-title {
          font-size: 0.8rem;
          line-height: 20px;
          white-space: normal;
        }
      }

      .icon-remove {
        width: 20px;
        height: 14px;
        // border: 1px solid red;
        color: #0e3359;
        background: white;
        position: absolute;
        right: 2px;
        line-height: 10px;
        cursor: pointer;
      }
    }
  }

  .area-drop-zone {
    font-weight: 500;
    padding: 1rem;

    button {
      border: 1px solid black;
      padding: 2px 12px;
      margin: 0.5rem;
      background: #9e9e9eab;
      color: black;
    }
  }
}

.document-dialog {
  .MuiPaper-root {
    background-color: #dff2ff;
    max-width: 100%;
    width: 100%;
    min-width: 450px;
    min-height: 400px;
    margin-top: 0;
  }

  .MuiDialogTitle-root {
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
    line-height: normal;

    .icon {
      min-width: 25px;
      height: 25px;
      border: 1px solid;
      margin-right: 5px;
      background-color: white;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    .icon-active {
      color: #2196f3;
    }

    .icon-text {
      padding-right: 5px;
      padding-left: 5px;
    }

    .icon-disable {
      opacity: 0.8 !important;
      cursor: no-drop !important;
    }

    .div-left {
      display: flex;
    }

    .div-right {
      display: flex;
      margin-left: auto;
    }
  }

  .MuiDialogContent-root {
    margin: 0 1rem 1rem 1rem;
    background-color: white;
    padding: 0.5rem;
  }
}

.document-confirm-dialog {
  padding-right: 280px;

  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiPaper-root {
    background-color: white;
    //max-width: 100%;
    width: 300px;
    height: 250px;

    textarea {
      min-width: 100%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .MuiDialogTitle-root {
    flex-direction: column-reverse !important;
    padding: 0.3rem !important;
    .div-left{
      margin-top: 0.2rem;
      .icon{
        margin-right: 1px;
      }
      .MuiInputBase-root:before{
        border: 0px !important;
      }
      .MuiInputBase-root{
        input{
          padding: 0px 2px;
        }
      }
    }
    .div-right{
      .MuiCheckbox-root{
        padding: 9px 4px;
      }
    }
  }
  
  .main {
    display: contents;

    .close-sidebar {
      display: none !important;
    }

    .render {
      width: 100% !important;
    }

    .sidebar {
      max-width: 100%;

      .list-choose-colors {
        max-width: 100%;
        overflow-y: scroll;
        height: calc(100vh / 3) !important;
      }
    }
  }

  .document-dialog {
    width: 100%;
    padding: 10px;
  }
}

.color-dialog {
  display: inline-block;
  position: relative;
  background: #fff;
  padding: 5px;
  width: 220px;
  box-shadow: 0 -5px 20px 0 rgb(218 224 235 / 80%);
  border: 0 solid transparent;
  border-radius: 0.25rem;
  right: -33px;
  top: -25px;
  z-index: 1;
  color: #000;
  font-size: 13px;

  .close {
    font-size: 20px !important;
    position: absolute;
    top: -3px;
    width: 15px;
    height: 15px;
    right: 5px;
    cursor: pointer;
  }

  p {
    width: 100%;
    margin: 0;
    padding: 5px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      float: left;
      margin: 5px;
      position: relative;
      height: 20px;

      span {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 3px;
        font-size: 8px;
        line-height: 23px;
        text-align: center;
        color: #000;
      }
    }

    li:first-child span {
      border: 1px solid #ccc;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
      position: absolute;
      top: 0;
      left: -8px;
    }
  }
}

.document-agreed {
  line-height: 2rem;
  max-width: 550px;
  margin: 0 auto;

  //font-family: ui;
  .check-list {
    font-size: 0.8rem;

    .MuiTypography-root {
      font-family: unset;
    }

    .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }

  .text-1 {
    padding-left: 30px;
  }

  .text-2 {
    height: 100px;
  }
}

.cg-btn{
  background-color: #9e9e9e !important;
  border-bottom: 1px solid black !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.cg-btn p{
  color: #000;
  width: 100%;
}

.cg-btn-active{
  background-color: #414bb2 !important;
  border-bottom: 1px solid white !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.cg-btn-active p{
  color: #ffffff;
  width: 100%;
}

.cg-modal{
  display: flex;
  width: 100%;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  padding: 12px 30px 24px 30px;
  border-radius: 10px;
  &-form{
    display: flex;
    gap: 20px;
  }
  &-input{
    min-width: 400px;
    border-radius: 5px;
    &::placeholder{
      display: flex;
      text-align: center;
    }
  }
  &-title{
    text-align: center;
    padding-bottom: 6px;
  }
  &-confirm{
    background-color: #414bb2;
    color: white;
    display: flex;
    padding-top: 2px;
    padding-inline:5px;
    cursor: pointer;
  }
}

.cg-close{
  position: absolute;
  top: -11px;
  right: -11px;
  background-color: white;
  border-radius: 100%;
  padding-inline: 6px;
  font-weight: bolder;
  border: 2px solid #414bb2;
  cursor: pointer;
}

.cg-dialog{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(1px);
  &-child{
    background-color: white;
    position: relative;
    border-radius: 5px;
    border: 3px solid #414bb2;
  }
}

.bottom-accordion-shape {
  position: absolute;
  bottom: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 100000;
  background-color: white;
  padding: 10px 0;

  .btn-under {
    // display: block;
    background-color: #414bb2;
    color: white;
    margin-top: 10px;
    width: 90%;
    padding: 10px 10px;
    &:hover {
      background-color: #414bb2;
    }
  }
  
  .button_invite {
    color: #2c4663;
    border-color: #2c4663;
    font-weight: 600;
    padding: 8px;
    width: 44%;
    &:hover {
      border-color: #2c4663;
    }
    &:first-child {
      margin-right: 2%;
    }
  }

  .send-and-request-unactive {
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    color: rgba(0, 0, 0, 0.3) !important;
    cursor: no-drop !important;
  }
}

@media (max-width: 600px) {
  .cg-modal{
    &-input{
      min-width: 200px;
    }
  }
  #shapespark {
    // overflow-x: hidden;
    // overflow-y: auto;
    height: 100%;
  }
  .canvas-footer-mobile{
    position: fixed;
  }
}